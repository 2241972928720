import { compareBetweenToDate, formatServerDateToDDMMYYYY, formatServerDateToMMYYYY, getStatusUser } from "../../helpers";

export const userGetters = {
  allUserListSorted: (state) => () => {
    const statusOrder = { 'active': 1, 'pending': 2, 'disable': 3 };
    return state.allUsers?.filter((user) => getStatusUser(user) !== 'created')
      .sort((a, b) => statusOrder[getStatusUser(a)] - statusOrder[getStatusUser(b)])
  },

  allUserListByPageGetters: (state) => (payload) => {
    const startIndex = payload.pageSize * (payload.page - 1);
    const endIndex = payload.pageSize * payload.page;
    const statusOrder = { 'active': 1, 'pending': 2, 'disable': 3 };
    const usersList = payload.filterStatusUser === 'all'
      ? state.allUsers?.sort((a, b) => statusOrder[getStatusUser(a)] - statusOrder[getStatusUser(b)])
      : state.allUsers?.filter((item) => getStatusUser(item) === payload.filterStatusUser);
    return usersList.filter(user => {
      const searchToLowerCase = payload.search?.toLowerCase();
      return (user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())) &&
        (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
    }).slice(startIndex, endIndex);
  },

  allUserLen: (state) => (payload) => {
    return payload.filterStatusUser === 'all' ? state.allUsers?.filter((user => {
      const searchToLowerCase = payload.search?.toLowerCase();
      return (user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())) &&
        (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
    })).length :
      state.allUsers?.filter((user) => {
        const searchToLowerCase = payload.search?.toLowerCase();
        return getStatusUser(user) === payload.filterStatusUser &&
          (user.nickName.toLowerCase().includes(searchToLowerCase) ||
            user.userName.toLowerCase().includes(searchToLowerCase) ||
            searchToLowerCase.includes(user.nickName.toLowerCase()) ||
            searchToLowerCase.includes(user.userName.toLowerCase())) &&
          (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
      })?.length;
  },

  userInShopListByPageGetters: (state) => (payload) => {
    const startIndex = payload.pageSize * (payload.page - 1);
    const endIndex = payload.pageSize * payload.page;
    const statusOrder = { 'active': 1, 'pending': 2, 'disable': 3 };
    const usersList = payload.filterStatusUser === 'all'
      ? state.userInShop?.sort((a, b) => statusOrder[getStatusUser(a)] - statusOrder[getStatusUser(b)])
      : state.userInShop?.filter((item) => getStatusUser(item) === payload.filterStatusUser);
    return usersList.filter(user => {
      const searchToLowerCase = payload.search?.toLowerCase();
      return (user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())) &&
        (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
    }).slice(startIndex, endIndex);
  },

  userInShopLen: (state) => (payload) => {
    return payload.filterStatusUser === 'all' ? state.userInShop?.filter((user) => {
      const searchToLowerCase = payload.search?.toLowerCase();
      return (user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())) &&
        (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
    })?.length :
      state.userInShop?.filter((user) => {
        const searchToLowerCase = payload.search?.toLowerCase();
        return getStatusUser(user) === payload.filterStatusUser &&
          (user.nickName.toLowerCase().includes(searchToLowerCase) ||
            user.userName.toLowerCase().includes(searchToLowerCase) ||
            searchToLowerCase.includes(user.nickName.toLowerCase()) ||
            searchToLowerCase.includes(user.userName.toLowerCase())) &&
          (getStatusUser(user) !== 'pending' && getStatusUser(user) !== 'created')
      })?.length;
  },


  userInShopListRequestApproval: (state) => (payload) => {
    const usersList = state.userInShop?.filter((item) => {
      const lastIndex = item?.packageUser?.length - 1 ?? 0;
      return (item?.packageUser[lastIndex]?.status !== 'disable' && item.packageUser[lastIndex]?.package?.status !== 'disable') &&
        item?.packageUser[lastIndex]?.package &&
        (item.packageUser[lastIndex].package?.end_date === null || !item.packageUser[lastIndex].package?.end_date) &&
        (item.packageUser[lastIndex]?.package?.start_date && compareBetweenToDate(formatServerDateToDDMMYYYY(item.packageUser[lastIndex]?.package?.start_date), payload.dateFilterHook));
    });
    return usersList;
  },

  userInShopListRequestRenew: (state) => (payload) => {
    const usersList = state.userInShop?.filter((item) => {
      const lastIndex = 0;
      return (item?.packageUser[lastIndex]?.status !== 'disable' && item.packageUser[lastIndex]?.package?.status !== 'disable') &&
        item?.packageUser[lastIndex]?.package && item?.packageUser?.length > 1 &&
        (item.packageUser[lastIndex].package?.end_date === null || !item.packageUser[lastIndex].package?.end_date) &&
        (item.packageUser[lastIndex]?.package?.start_date && compareBetweenToDate(formatServerDateToMMYYYY(item.packageUser[lastIndex]?.package?.start_date), payload.dateFilterHook));
    });
    return usersList;
  },

  userDetailGetters: (state) => (payload) => {
    return state.userList?.find((item) => item._id === payload._id);
  },

};
