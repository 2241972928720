<template>
  <div
    :class="{
      'sidebar-item-layers relative': true,
      'sidebar-item-layers-focus': sidebarFocus === index,
    }"
    v-on:click="handleUpdateFocusSideBar"
  >
    <router-link :to="link" exact tag="li" class="h-full">
      <span class="icon" v-html="icon"></span>
      <span class="name">{{ title }}</span>
      <span
        v-if="!openChildItem"
        v-html="ArrowDown"
        v-on:click="handleOpenChildItem"
        class="arrow absolute right-[5px] top-[50%] translate-y-[-50%] p-[10px] flex justify-content items-center"
      ></span>
      <span
        v-else
        v-html="ArrowUp"
        v-on:click="handleCloseChildItem"
        class="arrow absolute right-[5px] top-[50%] translate-y-[-50%] p-[10px] flex justify-content items-center"
      ></span>
    </router-link>
  </div>
  <div v-if="props.arrayChild.length > 0 && openChildItem" class="sidebar-sub-menu">
    <div
      v-for="menu in props.arrayChild"
      :key="menu?.id"
      v-on:click="handleFocusItemSubMenu(menu?.id, menu.path)"
      class="box-sub-menu-item h-full border-b-[1px] border-solid border-[#667085] cursor-pointer"
    >
      <router-link
        :to="menu.path"
        class="flex justify-center items-center gap-[12px]"
        exact
      >
        <span
          :class="{
            'name pl-[5px]': true,
            '!text-white': sidebarFocus === index && itemSubMenuFocus === menu?.id,
          }"
          >{{ menu.name }}</span
        >
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ArrowDown, ArrowUp } from "../../../assets/constant/svg";
const store = useStore();
const router = useRouter();
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  icon: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    required: true,
  },
  link: {
    type: String,
    default: "/",
  },
  arrayChild: {
    type: Array,
    default: [],
  },
});
const itemSubMenuFocus = computed(() => {
  return store.getters.getSidebarItemChildFocus(props.index);
});
const openChildItem = ref(
  (localStorage.getItem("openChildItemKey" + props.index) &&
    localStorage.getItem("openChildItemKey" + props.index) === "open") ??
    false
);
const sidebarFocus = computed(() => store.state.sidebarFocus);
const handleUpdateFocusSideBar = () => {
  store.dispatch("setSidebarFocus", props.index);
  if (!router.currentRoute.value.fullPath.includes(props?.link)) {
    router.push(`${props.link}`);
  }
};
const handleOpenChildItem = () => {
  localStorage.setItem("openChildItemKey" + props.index, "open");
  openChildItem.value = true;
};
const handleCloseChildItem = () => {
  localStorage.setItem("openChildItemKey" + props.index, "close");
  openChildItem.value = false;
};
const handleFocusItemSubMenu = (index, path) => {
  store.dispatch("setSidebarFocus", props.index);
  store.dispatch("setSidebarItemChildFocus", {
    key: props.index,
    index: index,
  });
  if (!router.currentRoute.value.fullPath.includes(path)) {
    router.push(path);
  }
};
</script>

<style lang="scss">
.sidebar-item-layers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0px 16px;
  border-left: 4px solid transparent;
  &:hover {
    background-color: #344054;
    .icon {
      path,
      circle {
        fill: var(--color-text-light);
      }
    }
    .name {
      color: var(--color-text-light);
    }
  }
  &:active {
    border-left: 4px solid var(--primary-color);
    .icon {
      path,
      circle {
        fill: var(--primary-color) !important;
      }
    }
    .name {
      color: var(--primary-color) !important;
    }
  }
  a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    .icon {
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: var(--color-text-gray);
        }
      }
    }
    .name {
      width: 142px;
      font-size: 16px;
      font-family: "Noto500";
      color: var(--color-text-gray);
    }
  }
}
.sidebar-sub-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 48px;
  .box-sub-menu-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    gap: 12px;
    padding-left: 40px;
    border-left: 4px solid transparent;
    &:hover {
      background-color: #344054;
      path,
      circle {
        fill: var(--color-text-light);
      }
      .name {
        color: var(--color-text-light);
      }
    }
    &:active {
      background-color: #344054;
      path,
      circle {
        fill: var(--color-text-light);
      }
      .name {
        color: var(--color-text-light);
      }
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-text-gray);
      }
    }
    .name {
      font-size: 16px;
      font-family: "Noto500";
      color: var(--color-text-gray);
    }
  }
}
.sidebar-item-layers-focus {
  border-left: 4px solid var(--primary-color);
  background-color: #182230 !important;
  .icon {
    path,
    circle {
      fill: var(--primary-color) !important;
    }
  }
  .name {
    color: var(--primary-color) !important;
  }
}
</style>
