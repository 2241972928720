<template>
  <div class="wrap" v-if="true">
    <div class="left" v-if="!sideBarHide">
      <SideBar />
    </div>
    <div class="right">
      <div
        :class="{
          main: true,
          '!pl-[90px]': layoutMobile && !sideBarHide,
          '!pl-[0px]': sideBarHide,
        }"
        id="main"
      >
        <Main />
      </div>
    </div>
  </div>
  <div class="wrap-two" v-else>
    <keep-alive :include="cachedComponents">
      <router-view> </router-view>
    </keep-alive>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, onUnmounted, watch } from "vue";
import VueCookies from "vue-cookies";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Main from "./components/master/Main";
import SideBar from "./components/master/SideBar";
import { getToken } from "./helpers/token";
const store = useStore();
const route = useRoute();
const router = useRouter();

const routesSingleLayout = ["/", "/login"];
const sideBarHide = computed(() => store.state.hideSidebar);
const layoutMobile = computed(() => store.state.layoutMobile);

watch(
  () => route.path,
  (newPath) => {
    handleRoute();
    handleFocusSidebar(newPath);
  }
);
const handleFocusItemSubMenu = (key, index) => {
  store.dispatch("setSidebarItemChildFocus", {
    key: key,
    index: index,
  });
};
const handleFocusSidebar = (newPath) => {
  if (newPath === "/dashboard") {
    store.dispatch("setSidebarFocus", 1);
  } else if (newPath.includes("/store-management")) {
    store.dispatch("setSidebarFocus", 2);
  } else if (newPath.includes("/user-management")) {
    store.dispatch("setSidebarFocus", 3);
    if (newPath.includes("/total-users")) {
      handleFocusItemSubMenu(3, 1);
    } else if (newPath.includes("/users-by-store")) {
      handleFocusItemSubMenu(3, 2);
    } else if (newPath.includes("/account-permission")) {
      handleFocusItemSubMenu(3, 3);
    }
  } else if (newPath.includes("/extended-management")) {
    store.dispatch("setSidebarFocus", 4);
  } else {
    store.dispatch("setSidebarFocus", 1);
  }
};

const handleRoute = async () => {
  store.dispatch("setStatusHideSidebar", true);
  await router.isReady();
  let currentPathObject = router.currentRoute.value;
  if (!getToken()) {
    store.dispatch("setStatusHideSidebar", true);
    VueCookies.remove("access_token_master");
    router.push("/login");
    return;
  } else {
    const flagSingleLayout = routesSingleLayout.some((routes) => {
      const path = currentPathObject.path;
      if (path === routes || currentPathObject.name === "NotFound") {
        if (path === "/login" || path === "/") {
          router.push("/dashboard");
        }
        store.dispatch("setStatusHideSidebar", true);
        return true;
      }
      return false;
    });
    if (!flagSingleLayout && sideBarHide) {
      store.dispatch("setStatusHideSidebar", false);
    }
  }
};

const updateWindowWidth = () => {
  const viewportMeta = document.getElementById("viewportMeta");
  if (window.innerWidth <= 1200) {
    store.dispatch("setLayoutMobile", true);
    viewportMeta.setAttribute(
      "content",
      "width=device-width, initial-scale=0.6, maximum-scale=0.6, user-scalable=no"
    );
  } else {
    store.dispatch("setLayoutMobile", false);
    viewportMeta.setAttribute(
      "content",
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    );
  }
};

onBeforeMount(() => {
  handleRoute();
});

onMounted(() => {
  updateWindowWidth();
  window.addEventListener("resize", updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
