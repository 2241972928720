<template>
  <div
    v-if="!layoutMobile"
    class="create-shop-page min-h-screen px-[24px] py-[21px] pb-[250px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.stores_creation") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
    </div>
    <div
      class="w-[70%] min-h-[75vh] mt-[24px] mx-auto border-[1px] border-solid border-[#D0D5DD] rounded-[12px] p-[24px] bg-white"
    >
      <div class="header flex justify-between items-center gap-[20px]">
        <div class="shop-name">{{ shopInfoCreate.name }}</div>
      </div>
      <div class="content flex flex-col justify-start items-start gap-[20px] mt-[20px]">
        <div class="box-avatar flex-wrap flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_logo_new") }}</label
          >

          <div class="box-upload flex justify-start items-center gap-[32px]">
            <div class="avatar w-[80px] h-[80px] relative">
              <img
                @click="handleUploadImage"
                id="avatarShop"
                :src="getAvatarShopByName(shopInfoCreate.avatar)"
                alt=""
                @error="(e) => handleImageError(e, 'shop')"
                class="w-[80px] h-[80px] cursor-pointer rounded-full border-solid border-[#EAECF0] border-[1px] object-cover"
              />
              <button
                @click="handleUploadImage"
                class="absolute cursor-pointer flex justify-center items-center right-0 bottom-0 w-[28px] h-[28px] rounded-full p-[6px] bg-[#F38744]"
              >
                <span v-html="CameraWhiteIcon"></span>
              </button>
              <input
                class="hidden"
                type="file"
                id="shop-logo"
                accept="image/*"
                @change="handleShopAvatar"
              />
            </div>
            <button
              @click="handleDeleteShopAvatar"
              :class="{
                'flex justify-center items-center gap-[8px] rounded-[8px] py-[12px] px-[16px] border-[1px] border-[#ADB5C2] cursor-default border-solid': true,
                '!border-delete-text cursor-pointer': shopAvatar !== '',
              }"
            >
              <span
                class="flex justify-center items-center"
                v-html="shopAvatar !== '' ? TrashFilledIcon : TrashGrayIcon"
              ></span>
              <span
                :class="{
                  'flex justify-center items-center whitespace-nowrap text-[#ADB5C2] noto500 text-[14px] leading-[18px]': true,
                  '!text-delete-text': shopAvatar !== '',
                }"
                >{{ $t("common.logo_removal") }}
              </span>
            </button>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_name") }}</label
          >
          <input
            v-model="shopInfoCreate.name"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_telephone_number") }}</label
          >
          <input
            v-model="shopInfoCreate.phone"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.address") }}</label
          >
          <input
            v-model="shopInfoCreate.address"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]">
            {{ $t("common.area") }}</label
          >
          <a-select
            :placeholder="areaShop"
            :options="areaList"
            @change="handleSelectArea"
            showSearch
            class="flex-1 rounded-[8px] px-[14px] py-[10px] bg-white outline-none"
          >
            <template #suffixIcon> <span v-html="ArrowDownOrangeIconn"></span> </template
          ></a-select>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.account_creation_date") }}</label
          >
          <label
            class="flex-1 flex justify-left items-center h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          >
            <a-date-picker
              class="w-full h-full"
              :format="dateFormat"
              picker="date"
              :allowClear="false"
              :placeholder="''"
              v-model:value="dateSelected"
              :locale="locale"
            >
              <template #suffixIcon>
                <span v-html="DateIcon"></span>
              </template>
            </a-date-picker>
          </label>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.email_address") }}</label
          >
          <input
            v-model="shopInfoCreate.email"
            type="text"
            class="flex-1 h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-image-shop flex justify-start items-center gap-[8px]">
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.logo_removal") }}</label
          >
          <div
            class="box-upload-images flex-wrap flex flex-col justify-start items-start gap-[8px]"
          >
            <button
              @click="handleClickUpload"
              class="flex justify-center items-center gap-[8px] w-[140px] h-[40px] px-[16px] py-[12px] border-[1px] border-solid border-[#F38744] rounded-[8px]"
            >
              <span v-html="FileUploadIconOrange"></span>
              <span
                class="noto500 text-[14px] leading-[18px] text-[#F38744] whitespace-nowrap"
                >{{ $t("common.upload") }}</span
              >
            </button>
            <input
              id="img_upload"
              class="hidden"
              type="file"
              multiple="multiple"
              accept="image/*"
              @change="handleUploadStorePhoto"
            />
            <div class="flex flex-wrap justify-start items-start gap-[12px]">
              <div
                v-for="(img, index) in shopImageList"
                :key="index"
                class="relative w-[130px] h-[80px] rounded-[8px] overflow-hidden"
              >
                <img
                  :src="img"
                  alt=""
                  style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  class="min-w-[130px] w-[130px] h-[80px] rounded-[8px] object-cover border-solid border-[1px] border-[#D0D5DD]"
                />
                <button
                  @click="handleDeleteStorePhoto(index)"
                  class="absolute bottom-[4px] right-[4px] w-[28px] h-[28px] rounded-[4px] p-[6px] border-[1px] border-white border-solid bg-[#D0D5DD]"
                >
                  <span v-html="TrashWhiteIcon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-end items-center gap-[8px]">
          <button
            @click="handleCreateShop"
            class="w-[120px] h-[40px] rounded-[8px] bg-[#F38744] flex justify-center items-center gap-[8px]"
          >
            <span v-html="SaveWhiteIcon"></span>
            <span class="text-white">{{ $t("common.create") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="create-shop-page min-h-screen px-[24px] py-[21px] pb-[250px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.stores_creation") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
    </div>
    <div
      class="w-[100%] min-h-[75vh] mt-[24px] mx-auto border-[1px] border-solid border-[#D0D5DD] rounded-[12px] p-[24px] bg-white"
    >
      <div class="header flex justify-between items-center gap-[20px]">
        <div class="shop-name">{{ shopInfoCreate.name }}</div>
      </div>
      <div class="content flex flex-col justify-start items-start gap-[20px] mt-[20px]">
        <div
          class="w-full box-avatar flex-wrap flex justify-between items-center gap-[8px]"
        >
          <label
            class="min-w-[200px] noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_logo_new") }}</label
          >

          <div class="box-upload flex justify-start items-center gap-[32px]">
            <div class="avatar w-[80px] h-[80px] relative">
              <img
                @click="handleUploadImage"
                id="avatarShop"
                :src="getAvatarShopByName(shopInfoCreate.avatar)"
                alt=""
                @error="(e) => handleImageError(e, 'shop')"
                class="w-[80px] h-[80px] cursor-pointer rounded-full border-solid border-[#EAECF0] border-[1px] object-cover"
              />
              <button
                @click="handleUploadImage"
                class="absolute cursor-pointer flex justify-center items-center right-0 bottom-0 w-[28px] h-[28px] rounded-full p-[6px] bg-[#F38744]"
              >
                <span v-html="CameraWhiteIcon"></span>
              </button>
              <input
                class="hidden"
                type="file"
                id="shop-logo"
                accept="image/*"
                @change="handleShopAvatar"
              />
            </div>
            <button
              @click="handleDeleteShopAvatar"
              :class="{
                'flex justify-center items-center gap-[8px] rounded-[8px] py-[12px] px-[16px] border-[1px] border-[#ADB5C2] cursor-default border-solid': true,
                '!border-delete-text cursor-pointer': shopAvatar !== '',
              }"
            >
              <span
                class="flex justify-center items-center"
                v-html="shopAvatar !== '' ? TrashFilledIcon : TrashGrayIcon"
              ></span>
            </button>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_name") }}</label
          >
          <input
            v-model="shopInfoCreate.name"
            type="text"
            class="flex-1 w-full h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.store_telephone_number") }}</label
          >
          <input
            v-model="shopInfoCreate.phone"
            type="text"
            class="flex-1 w-full h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.address") }}</label
          >
          <input
            v-model="shopInfoCreate.address"
            type="text"
            class="flex-1 w-full h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
          >
            {{ $t("common.area") }}</label
          >
          <a-select
            :placeholder="areaShop"
            :options="areaList"
            @change="handleSelectArea"
            showSearch
            class="flex-1 w-full rounded-[8px] px-[14px] py-[10px] bg-white outline-none"
          >
            <template #suffixIcon> <span v-html="ArrowDownOrangeIconn"></span> </template
          ></a-select>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.account_creation_date") }}</label
          >
          <label
            class="flex-1 w-full max-h-[40px] flex justify-left items-center h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          >
            <a-date-picker
              class="w-full h-full"
              :format="dateFormat"
              picker="date"
              :allowClear="false"
              :placeholder="''"
              v-model:value="dateSelected"
              :locale="locale"
            >
              <template #suffixIcon>
                <span v-html="DateIcon"></span>
              </template>
            </a-date-picker>
          </label>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-col w-full flex justify-start items-center gap-[12px]">
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.email_address") }}</label
          >
          <input
            v-model="shopInfoCreate.email"
            type="text"
            class="flex-1 w-full h-[40px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[14px] py-[10px] bg-white outline-none"
          />
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div
          class="box-image-shop w-full flex flex-col justify-start items-center gap-[12px]"
        >
          <label
            class="w-full text-left noto500 text-[16px] leading-[20px] text-[#A3A9B6]"
            >{{ $t("common.logo_removal") }}</label
          >
          <div
            class="box-upload-images w-full flex-wrap flex flex-col justify-start items-start gap-[8px]"
          >
            <button
              @click="handleClickUpload"
              class="flex w-full justify-center items-center gap-[8px] w-[140px] h-[40px] px-[16px] py-[12px] border-[1px] border-solid border-[#F38744] rounded-[8px]"
            >
              <span v-html="FileUploadIconOrange"></span>
              <span
                class="noto500 text-[14px] leading-[18px] text-[#F38744] whitespace-nowrap"
                >{{ $t("common.upload") }}</span
              >
            </button>
            <input
              id="img_upload"
              class="hidden"
              type="file"
              multiple="multiple"
              accept="image/*"
              @change="handleUploadStorePhoto"
            />
            <div class="flex w-full flex-wrap justify-center items-start gap-[12px]">
              <div
                v-for="(img, index) in shopImageList"
                :key="index"
                class="relative w-[130px] h-[80px] rounded-[8px] overflow-hidden"
              >
                <img
                  :src="img"
                  alt=""
                  style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px"
                  class="min-w-[130px] w-[130px] h-[80px] rounded-[8px] object-cover border-solid border-[1px] border-[#D0D5DD]"
                />
                <button
                  @click="handleDeleteStorePhoto(index)"
                  class="absolute bottom-[4px] right-[4px] w-[28px] h-[28px] rounded-[4px] p-[6px] border-[1px] border-white border-solid bg-[#D0D5DD]"
                >
                  <span v-html="TrashWhiteIcon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p class="line w-full h-[1px] bg-[#D0D5DD] relative"></p>
        <div class="box-input flex-wrap w-full flex justify-end items-center gap-[8px]">
          <button
            @click="handleCreateShop"
            class="w-full h-[40px] rounded-[8px] bg-[#F38744] flex justify-center items-center gap-[8px]"
          >
            <span v-html="SaveWhiteIcon"></span>
            <span class="text-white">{{ $t("common.create") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  ArrowDownOrangeIconn,
  CameraWhiteIcon,
  FileUploadIconOrange,
  SaveWhiteIcon,
  TrashGrayIcon,
  TrashWhiteIcon,
} from "../../../assets/constant/svg";
import {
  addModal,
  createFormDataFromObject,
  formatDateForEn,
  formatDateForJapanese,
  formatServerDateToJapaneseYYYYMMDD,
  getAvatarShopByName,
  getCurrentDate,
  handleImageError,
} from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import { TrashFilledIcon } from "../../../assets/constant/constant";
import locale from "ant-design-vue/es/date-picker/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
const dateFormat = "YYYY年M月D日";
dayjs.locale("ja");
const layoutMobile = computed(() => store.state.layoutMobile);
const dateSelected = ref(dayjs(getCurrentDate(), "YYYY-MM-DD"));
const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const shopRequestInfo = computed(() => {
  return store.state.shopRequestInfo;
});
const shopInfoCreate = reactive({
  name: "",
  phone: "",
  email: "",
  address: "",
  avatar: "",
  location: "",
  createdAt:
    localStorage.getItem("lang") === "en"
      ? formatDateForEn(new Date())
      : formatDateForJapanese(new Date()),
  storePhoto: [],
});
const routes = computed(() => [
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/store-management",
    breadcrumbName: t("common.store_management"),
  },
  {
    path: "/store-management/store-request",
    breadcrumbName: t("common.store_approval"),
  },
  {
    path: "/store-management/store-request/create-shop-page",
    breadcrumbName: shopInfoCreate?.name,
  },
]);
const shopAvatar = ref("");
const shopImageList = ref([]);
const handleUploadStorePhoto = (event) => {
  const files = event.target.files;
  if (files) {
    Array.from(files).forEach((item) => {
      shopInfoCreate.storePhoto.push(item);
      const photoUrl = URL.createObjectURL(item);
      shopImageList.value.push(photoUrl);
    });
  }
};
const handleDeleteStorePhoto = (index) => {
  shopImageList.value.splice(index, 1);
  shopInfoCreate.storePhoto.splice(index, 1);
};
watch(shopRequestInfo, () => {
  if (shopRequestInfo) {
    shopInfoCreate.name = shopRequestInfo.value?.name ?? "";
    shopInfoCreate.phone = shopRequestInfo.value?.phone ?? "";
    shopInfoCreate.avatar = shopRequestInfo.value?.avatar ?? "";
    shopInfoCreate.address = shopRequestInfo.value?.address ?? "";
    shopInfoCreate.email = shopRequestInfo.value?.email ?? "";
    shopInfoCreate.createdAt = shopRequestInfo.value?.createdAt
      ? formatServerDateToJapaneseYYYYMMDD(shopRequestInfo.value?.createdAt)
      : localStorage.getItem("lang") === "en"
      ? formatDateForEn(new Date())
      : formatDateForJapanese(new Date());
  }
});

// Handle area
const areaList = computed(() => store.getters.areaListGetters());
const areaShop = computed(() => {
  return areaList.value?.find((area) => {
    return area.id === shopRequestInfo.value.location;
  })?.value;
});
const handleSelectArea = (value) => {
  shopInfoCreate.location = areaList.value?.find((area) => area.value === value)?.id;
};

const handleDeleteShopAvatar = () => {
  if (shopAvatar.value !== "") {
    document.getElementById("avatarShop").src = getAvatarShopByName("");
    shopAvatar.value = "";
  }
};
const handleShopAvatar = (event) => {
  const selectedFile = event.target.files[0];
  shopAvatar.value = selectedFile;
  const imageUrl = URL.createObjectURL(selectedFile);
  if (document.getElementById("avatarShop")) {
    document.getElementById("avatarShop").src = imageUrl;
    document.getElementById("avatarShop").src = imageUrl;
  }
};
const handleUploadImage = () => {
  if (document.getElementById("shop-logo")) {
    document.getElementById("shop-logo").click();
  }
};

const handleClickUpload = () => {
  if (document.getElementById("img_upload")) {
    document.getElementById("img_upload").click();
  }
};
const handleCreateShop = () => {
  console.log(shopInfoCreate);
  addModal(ConfirmModal, {
    title: t("common.do_you_create_this_store"),
    textBtnConfirm: t("common.create"),
    textBtnClose: t("common.cancel_delete"),
    onConfirm: () => {
      const arrImage =
        shopAvatar.value !== "" && shopAvatar.value !== null
          ? [shopAvatar.value].concat(shopInfoCreate.storePhoto)
          : shopInfoCreate.storePhoto;
      const data = {
        ...shopInfoCreate,
        location:
          shopInfoCreate.location !== ""
            ? shopInfoCreate.location
            : shopRequestInfo.value.location,
        createdAt: dateSelected.value,
        checkNotAvatar: 1,
      };
      if (shopAvatar.value !== "") {
        delete data.checkNotAvatar;
      }
      delete data.storePhoto;
      delete data.avatar;
      const formData = createFormDataFromObject(data);
      arrImage?.forEach((img) => {
        formData.append("multiImage", img);
      });
      store.dispatch("createShop", {
        shopInfo: formData,
        idShop: shopRequestInfo.value?._id,
        onSuccess: () => {
          store.dispatch("getAllShopAllStatus");
          setTimeout(() => {
            router.replace("/store-management");
          }, 2000);
        },
      });
    },
  });
};
onMounted(() => {
  if (areaList && areaList.value.length <= 0) {
    store.dispatch("getAreaList");
  }
  if (route.query.id) {
    store.dispatch("getShopRequestInfo", {
      shopId: route.query.id,
      navigationError: () => {
        router.replace("/store-management");
      },
    });
  }
});
onUnmounted(() => {
  store.commit("clearShopRequestInfo");
});
</script>
<styles lang="scss">
.create-shop-page {
  input {
    font-family: Noto400;
    font-size: 14px;
    line-height: 22px;
    color: #1d2939;
  }
  .ant-picker {
    width: 100% !important;
    padding-left: 0px !important;
  }
  .ant-picker-input {
    input {
      text-align: left !important;
    }
  }
}
</styles>
