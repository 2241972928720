import axios from 'axios';
import VueCookies from 'vue-cookies';
import { useRouter } from 'vue-router';
const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    'Cache-Control':
      'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
    Pragma: 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 3000000
});

instance.interceptors.request.use(
  (config) => {
    config.data = config.data || [];

    config.params = config.params || {};

    if (!config.noNeedToken) {
      config.headers = {
        Authorization: `Bearer ${config.token}`
      };
    }

    if (config.isFormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      VueCookies.remove('access_token_master');
      setTimeout(() => {
        console.log(window.location.pathname);
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }, 500);
      return error.response;
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
