<template>
  <div
    v-if="!layoutMobile"
    class="user-by-store flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.users_by_store") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="rightside">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-[380px] h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchUserKey"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            v-on:click="searchUserRenderDebounce(searchUserKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center gap-[10px] noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            <span v-if="!loadingSearchUser" class="whitespace-nowrap">{{
              $t("common.exploration")
            }}</span>
            <span v-if="loadingSearchUser">
              <a-spin :indicator="indicatorLoadingSearchUser" />
            </span>
          </button>
        </label>
      </div>
    </div>
    <div
      class="flex flex-col justify-start items-start w-full min-h-[65vh] mt-[24px] mb-[40px] rounded-[12px] border-solid border-[#D0D5DD] border-[1px] bg-white"
      :style="{ 'max-width: calc(100% - 48px)': true }"
    >
      <div
        class="header flex justify-between items-center w-full h-[68px] px-[24px] py-[12px] bg-[#475467] rounded-t-[12px]"
      >
        <p class="noto700 text-[20px] leading-[28px] text-white">
          {{ userRender.length ?? 0 }} {{ " " + $t("common.user_accounts") }}
        </p>
        <div class="flex justify-center items-center gap-[12px]">
          <SelectUser :handleSelectStatusUserFilter="handleSelectStatusUserFilter" />
          <SelectShop :handleSelectShop="handleSelectShop" />
        </div>
      </div>
      <div class="w-full flex flex-1 justify-start items-center">
        <div
          v-if="userRender && userRender.length > 0 && !loadingApi"
          class="bg-white flex flex-wrap justify-center items-center w-full px-[24px] py-[20px] gap-[24px] rounded-[12px]"
        >
          <UserItem v-for="user in userRender" :user="user" :key="user._id" />
        </div>
        <div
          v-if="userRender && userRender.length <= 0 && loadingApi"
          class="bg-white flex flex-1 flex-wrap justify-center items-center w-full px-[24px] py-[20px] gap-[24px] rounded-[12px]"
        >
          <a-spin :indicator="indicatorLoadingApi" />
        </div>
        <div
          v-if="userRender && userRender.length <= 0 && !loadingApi"
          class="bg-white flex flex-1 flex-wrap justify-center items-center w-full px-[24px] py-[20px] gap-[24px] rounded-[12px]"
        >
          <EmptyData />
        </div>
      </div>
    </div>
    <div
      :class="{
        'nav-page w-full flex justify-center items-center w-1/1 h-[40px] ': true,
      }"
      v-if="usersLen && usersLen > 0"
    >
      <a-pagination
        v-model:current="pageCurrent"
        :total="usersLen"
        :pageSize="pageSize"
        :showSizeChanger="false"
        :responsive="true"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'" class="btn-navi">
            <span v-html="ArrowLeft"></span>
            <span>{{ $t("common.previous") }}</span>
          </a>
          <a v-else-if="type === 'next'" class="btn-navi">
            <span>{{ $t("common.next") }}</span>
            <span v-html="ArrowRight"></span>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
  <div
    v-else
    class="user-by-store flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <MenuTab />
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.users_by_store") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="rightside">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-[380px] h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchUserKey"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            v-on:click="searchUserRenderDebounce(searchUserKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center gap-[10px] noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            <span v-if="!loadingSearchUser" class="whitespace-nowrap">{{
              $t("common.exploration")
            }}</span>
            <span v-if="loadingSearchUser">
              <a-spin :indicator="indicatorLoadingSearchUser" />
            </span>
          </button>
        </label>
      </div>
    </div>
    <div
      class="flex flex-col justify-start items-start gap-[20px] w-full min-h-[65vh] mt-[24px] mb-[40px] rounded-[12px] border-solid border-[#D0D5DD] border-[1px] bg-white"
      :style="{ 'max-width: calc(100% - 48px)': true }"
    >
      <div
        class="header flex justify-between items-center w-full h-[68px] px-[24px] py-[12px] bg-[#475467] rounded-t-[12px]"
      >
        <p class="noto700 text-[20px] leading-[28px] text-white">
          {{ userRender.length ?? 0 }} {{ " " + $t("common.user_accounts") }}
        </p>
      </div>
      <div class="flex flex-col justify-center w-full items-center gap-[12px] px-[24px]">
        <SelectUser :handleSelectStatusUserFilter="handleSelectStatusUserFilter" />
        <SelectShop :handleSelectShop="handleSelectShop" />
      </div>
      <div class="w-full flex flex-1 justify-start items-center">
        <div
          v-if="userRender && userRender.length > 0 && !loadingApi"
          class="bg-white flex flex-wrap justify-center items-center w-full px-[24px] pb-[20px] pt-[12px] gap-[24px] rounded-[12px]"
        >
          <UserItem v-for="user in userRender" :user="user" :key="user._id" />
        </div>
        <div
          v-if="userRender && userRender.length <= 0 && loadingApi"
          class="bg-white flex flex-1 flex-wrap justify-center items-center w-full px-[24px] py-[20px] gap-[24px] rounded-[12px]"
        >
          <a-spin :indicator="indicatorLoadingApi" />
        </div>
        <div
          v-if="userRender && userRender.length <= 0 && !loadingApi"
          class="bg-white flex flex-1 flex-wrap justify-center items-center w-full px-[24px] py-[20px] gap-[24px] rounded-[12px]"
        >
          <EmptyData />
        </div>
      </div>
    </div>
    <div
      :class="{
        'nav-page w-full flex justify-center items-center w-1/1 h-[40px] ': true,
      }"
      v-if="usersLen && usersLen > 0"
    >
      <a-pagination
        v-model:current="pageCurrent"
        :total="usersLen"
        :pageSize="pageSize"
        :showSizeChanger="false"
        :responsive="true"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'" class="btn-navi">
            <span v-html="ArrowLeft"></span>
            <span>{{ $t("common.previous") }}</span>
          </a>
          <a v-else-if="type === 'next'" class="btn-navi">
            <span>{{ $t("common.next") }}</span>
            <span v-html="ArrowRight"></span>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ArrowLeft, ArrowRight, SearchIcon } from "../../../assets/constant/svg";
import {} from "../../../assets/constant/variable";
import { containsNonWhitespaceCharacters, useDebounce } from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import EmptyData from "../../master/EmptyData.vue";
import UserItem from "./components/UserItem.vue";
import MenuTab from "./components/MenuTab.vue";
import SelectShop from "./components/SelectShop.vue";
import SelectUser from "./components/SelectUser.vue";
const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const loadingApi = ref(false);
const indicatorLoadingApi = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const indicatorLoadingSearchUser = h(LoadingOutlined, {
  style: {
    fontSize: "20px",
    color: "white",
  },
  spin: true,
});
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/user-management/total-users",
    breadcrumbName: t("common.user_management"),
  },
  {
    path: "/user-management/users-by-store",
    breadcrumbName: t("common.users_by_store"),
  },
]);

// select filter status user
const selectedStatusUserFilter = ref({
  id: 0,
  status: "all",
  name: t("common.all_accounts"),
});
const handleSelectStatusUserFilter = (item) => {
  router.replace({
    query: { ...route.query, status: item.status },
  });
  pageCurrent.value = 1;
  selectedStatusUserFilter.value = item;
};

// render user in page currrent
const pageCurrent = ref(1);
const pageSize = ref(15);
const searchUserKey = ref("");
const usersLen = computed(() =>
  store.getters.userInShopLen({
    filterStatusUser: selectedStatusUserFilter.value.status,
    search: searchUserKey.value,
  })
);
const users = computed(() =>
  store.getters.userInShopListByPageGetters({
    page: pageCurrent.value,
    pageSize: pageSize.value,
    filterStatusUser: selectedStatusUserFilter.value?.status,
    search: searchUserKey.value,
  })
);

// search user list
const loadingSearchUser = ref(false);
const userRender = ref([]);
const searchUserRender = (searchKey) => {
  const searchToLowerCase = searchKey.toLowerCase();
  if (!searchKey || !containsNonWhitespaceCharacters(searchKey)) {
    userRender.value = users.value;
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      delete newQuery.search;
      router.push({
        query: newQuery,
      });
    }, 200);
  } else {
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      router.push({
        query: newQuery,
      });
    }, 200);
    userRender.value = users.value.filter((user) => {
      return (
        user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())
      );
    });
  }
  loadingSearchUser.value = false;
};
const searchUserRenderDebounce = useDebounce(searchUserRender, 1000);
watch([searchUserKey], () => {
  loadingSearchUser.value = true;
  pageCurrent.value = 1;
  searchUserRenderDebounce(searchUserKey.value);
});
watch([users], () => {
  searchUserRenderDebounce(searchUserKey.value);
});
watch(pageCurrent, () => {
  router.replace({
    query: { ...route.query, page: pageCurrent.value },
  });
  searchUserRender(searchKey.value);
});
// load shop list render
const shopSelected = ref(null);
const handleSelectShop = (shop) => {
  router.replace({
    query: { ...route.query, shop: shop._id },
  });
  pageCurrent.value = 1;
  shopSelected.value = shop;
};

// mounted
watch(route.path, () => {
  handleGetQueryFromPath();
});
const handleGetQueryFromPath = () => {
  const search = route.query.search;
  if (search) {
    searchUserKey.value = search;
  }
};
watch(shopSelected, () => {
  store.dispatch("getUserInShop", shopSelected.value._id);
});
onMounted(() => {
  if (users.value.length <= 0) {
    loadingApi.value = true;
    store.dispatch("getAllShops");
    setTimeout(() => {
      loadingApi.value = false;
    }, 1000);
  }
  handleGetQueryFromPath();
});
</script>
<style lang="sass"></style>
