<template>
  <div
    id="sidebar"
    :class="{
      'sidebar-wrap': true,
      'sidebar-collapse': layoutMobile,
    }"
  >
    <div
      class="logo w-full h-[60px] overflow-hidden border-b"
      style="border-color: var(--line-color)"
    >
      <img
        v-if="layoutMobile"
        src="../../assets/images/svg/img_logo.svg"
        alt=""
        class="w-80 h-[62px] object-cover"
      />
      <img
        v-else
        src="../../assets/images/svg/logo_default.svg"
        alt=""
        class="w-full h-[62px] object-cover"
      />
    </div>
    <div
      class="menu-links flex flex-col justify-start items-start pt-2.5 w-full flex-1 border-b"
      style="border-color: var(--line-color)"
    >
      <SideBarItem
        :icon="HomeIcon"
        :title="$t('common.dashboard')"
        :index="1"
        link="/dashboard"
      />
      <SideBarItem
        :icon="StoreIconBig"
        :title="$t('common.store_management')"
        :index="2"
        link="/store-management"
      />
      <SideBarItemLayers
        :icon="UserIcon"
        :title="$t('common.user_management')"
        :index="3"
        link="/user-management/total-users"
        :arrayChild="menusUserTab()"
      />
      <SideBarItem
        :icon="ExtendedIcon"
        :title="$t('common.extended_management')"
        :index="4"
        link="/extended-management"
      />
    </div>
    <div
      class="footer flex flex-col justify-start items-start w-full h-[116px] border-t-[1px] border-solid border-b p-[12px]"
    >
      <button
        class="w-full flex-1 flex justify-start items-center gap-[8px] px-[16px] py-[10px]"
      >
        <span v-html="KeyIcon"></span>
        <span class="text-btn text-[#98A2B3] text-[16px] noto500 leading-[20px]">{{
          $t("common.password_exchange")
        }}</span>
      </button>
      <button
        @click="handleLogout"
        class="w-full flex-1 flex justify-start items-center gap-[8px] px-[16px] py-[10px]"
      >
        <span v-html="LogOutIcon"></span>
        <span class="text-btn text text-[#F04438] text-[16px] noto500 leading-[20px]">{{
          $t("common.logout")
        }}</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  ExtendedIcon,
  HomeIcon,
  KeyIcon,
  LogOutIcon,
  StoreIconBig,
  UserIcon,
} from "../../assets/constant/svg";
import SideBarItem from "./components/SideBarItem.vue";
import SideBarItemLayers from "./components/SideBarItemLayers.vue";
import VueCookies from "vue-cookies";
import { menusUserTab } from "../../assets/constant/variable";

const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const handleLogout = () => {
  VueCookies.remove("access_token_master");
  router.replace("/login");
};
</script>
<style lang="scss">
.sidebar-wrap {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 240px;
  height: 100%;
  background-color: var(--brg-sidebar);
  overflow: hidden;
  z-index: 100;
}
.sidebar-collapse {
  width: 90px;
  .menu-links {
    .sidebar-item,
    .sidebar-item-layers {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        .name {
          display: none;
        }
        .arrow {
          padding: 0px !important;
        }
      }
    }
  }
  .footer {
    button {
      justify-content: center !important;
    }
    .text-btn {
      display: none;
    }
  }
  .arrow {
    display: none;
  }
  .sidebar-sub-menu {
    display: none;
  }
}
</style>
