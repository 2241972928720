<template>
  <div
    :class="{
      'user-item relative flex flex-wrap justify-between items-center gap-[12px] p-[20px] border border-gray-border rounded-lg': true,
      'bg-[#EAECF0]': getStatusUser(data) === 'disable',
    }"
  >
    <div
      class="avatar flex justify-center items-center w-[48px] h-[48px] !max-w-[48px] !max-h-[48px]"
    >
      <img
        :src="getAvatarUserByName(data?.avatar)"
        class="rounded-full w-[48px] h-[48px] !max-w-[48px] !max-h-[48px] object-cover"
        @error="(e) => handleImageError(e, '')"
      />
    </div>
    <div class="user-info flex-1 min-w-[140px]">
      <div
        class="nick-name w-full noto500 text-[16px] text-left leading-[24px] text-[#182230]"
      >
        {{ shortenString(data?.nickName, lengthCharacterNameStore) }}
      </div>
      <div
        class="user-name w-full noto400 text-[14px] text-left leading-[22px] text-[#667085]"
      >
        {{ shortenString(data?.userName, lengthCharacterNameStore) }}
      </div>
    </div>
    <div class="status flex justify-center items-center">
      <span
        v-if="getStatusUser(data)"
        :class="{
          'flex justify-center items-center w-[56px] max-w-[56px] h-[24px] rounded-[8px] border-[1px] border-solid text-[12px] noto500 leading-[22px]': true,
          'border-[#F38744] text-[#F38744] bg-white': getStatusUser(data) === 'active',
          'border-[#667085] text-[#667085] bg-white': getStatusUser(data) === 'pending',
          'border-[#D0D5DD] text-white !bg-[#D0D5DD]': getStatusUser(data) === 'disable',
        }"
      >
        {{
          getStatusUser(data) === "active"
            ? $t("common.under_contract")
            : getStatusUser(data) === "pending"
            ? $t("common.unauthorized")
            : $t("common.in_active")
        }}
      </span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted, onUnmounted, ref } from "vue";
import {
  getAvatarUserByName,
  getStatusUser,
  handleImageError,
  shortenString,
} from "../../../../helpers";
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const lengthCharacterNameStore = ref(12);
const updateWindowWidth = () => {
  if (window.innerWidth > 1470) {
    lengthCharacterNameStore.value = 12;
  } else if (window.innerWidth > 1200) {
    lengthCharacterNameStore.value = 25;
  } else {
    lengthCharacterNameStore.value = 100;
  }
};
onMounted(() => {
  updateWindowWidth();
  window.addEventListener("resize", updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>
<style lang="scss" scoped>
$four_item_on_row: 4;
$three_item_on_row: 3;
$two_item_on_row: 2;
$gap: 24px;
.user-item {
  min-width: 200px;
  width: calc(
    (100% / #{$four_item_on_row}) -
      (#{$gap} * (#{$four_item_on_row} - 1) / #{$four_item_on_row})
  );
  @media screen and (max-width: 1700px) {
    width: calc(
      (100% / #{$three_item_on_row}) -
        (#{$gap} * (#{$three_item_on_row} - 1) / #{$three_item_on_row})
    );
  }
  @media screen and (max-width: 1400px) {
    width: calc(
      (100% / #{$two_item_on_row}) -
        (#{$gap} * (#{$two_item_on_row} - 1) / #{$two_item_on_row})
    );
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
    .status {
      max-width: 100% !important;
      width: 100% !important;
    }
    .avatar {
      width: 100% !important;
      height: 100% !important;
    }
    .user-info {
      width: 100% !important;
      .nick-name,
      .user-name {
        text-align: center;
      }
    }
  }
}
</style>
