import axiosConfig from '../../config/axiosConfig';

export const usersAction = {

  async getAllUsers(context) {
    try {
      const res = await axiosConfig.get('/user-of-shop?type=get_all&status[]=active&status[]=pending&status[]=disable');
      if (res?.status === 200 || res?.status === 201) {
        context.commit('GET_ALL_USERS', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserInShop(context, shopId) {
    try {
      const res = await axiosConfig.get(`/user-of-shop?shop=${shopId}&status[]=active&status[]=pending&status[]=disable`);
      context.commit('GET_USER_IN_SHOP', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserDetails(context, userId) {
    try {
      const res = await axiosConfig.get(`/user-of-shop/${userId}`);
      context.commit('GET_USER_DETAILS', res.data);
    } catch (error) {
      context.commit('GET_USER_DETAILS', {});
      throw new Error(error);
    }
  },

  async allowPermission(context, payload) {
    try {
      const res = await axiosConfig.put(`/purchased-packages/update-package-user`, payload.data);
      if (res.status === 200 || res.status === 201) {
        context.commit('ALLOW_PERMISSION', payload);
      } else {
        context.commit('ALLOW_PERMISSION_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async restoreUser(context, payload) {
    try {
      const res = await axiosConfig.put(`/user-of-shop/restore/${payload.userId}`);
      if (res.status === 200 || res.status === 201) {
        context.commit('RESTORE_USER', {
          onSuccess: payload.onSuccess
        });
      }
    } catch (error) {
      context.commit('RESTORE_USER_ERROR', {})
    } finally {
      payload.onOffLoading();
    }
  },
  
  async deleteUser(context, payload) {
    try {
      const res = await axiosConfig.delete(`/user-of-shop/${payload.userId}`);
      res.status === 200
        ? context.commit('DELETE_USER', {
          onSuccess: payload.onSuccess
        })
        : context.commit('DELETE_USER_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      payload.onOffLoading();
    }
  },

  async disableUser(context, payload) {
    try {
      const res = await axiosConfig.put(`/user-of-shop/disable-user/${payload.userId}`);
      res.status === 200
        ? context.commit('DISABLE_USER', {
          onSuccess: payload.onSuccess
        })
        : context.commit('DISABLE_USER_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      payload.onOffLoading();
    }
  },

  async deletePermanentlyUsers(context, payload) {
    try {
      const res = await axiosConfig.delete('/user-of-shop/delete-forever', {
        data: {
          ids: payload.ids
        }
      });
      res.status === 200 || res.status === 201
        ? context.commit('DELETE_PERMANENTLY_USERS', {
          onSuccess: payload.onSuccess
        })
        : context.commit('DELETE_PERMANENTLY_USERS_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      payload.onOffLoading();
    }
  }

};
