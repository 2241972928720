<template>
  <div
    v-if="!layoutMobile"
    class="total-user flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[20px]">
      <div class="flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.total_users") }}
          </h2>
        </div>
        <div class="breadcrumb">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="box-search-user">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-[380px] h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchKey"
            @keydown.enter="searchUserRenderDebounce(searchKey)"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            v-on:click="searchUserRenderDebounce(searchKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center gap-[10px] noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            <span v-if="!loadingSearch" class="whitespace-nowrap">{{
              $t("common.exploration")
            }}</span>
            <span v-if="loadingSearch">
              <a-spin :indicator="indicatorLoadingSearch" />
            </span>
          </button>
        </label>
      </div>
      <SelectUser :handleSelectStatusUserFilter="handleSelectStatusUserFilter" />
    </div>
    <div
      v-if="userRender && userRender.length > 0"
      class="w-full min-h-[75vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[24px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <UserItem v-for="user in userRender" :user="user" :key="user._id" />
      </div>
    </div>
    <div
      v-if="userRender && userRender.length <= 0 && loadingApi"
      class="w-full min-h-[75vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[24px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <a-spin :indicator="indicatorLoadingApi" />
      </div>
    </div>
    <div
      v-if="userRender && userRender.length <= 0 && !loadingApi"
      class="w-full min-h-[75vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[24px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <EmptyData />
      </div>
    </div>
    <div
      :class="{
        'nav-page w-full flex justify-center items-center w-1/1 h-[40px] ': true,
      }"
      v-if="usersLen && usersLen > 0"
    >
      <a-pagination
        v-model:current="pageCurrent"
        :total="usersLen"
        :pageSize="pageSize"
        :showSizeChanger="false"
        :responsive="true"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'" class="btn-navi">
            <span v-html="ArrowLeft"></span>
            <span>{{ $t("common.previous") }}</span>
          </a>
          <a v-else-if="type === 'next'" class="btn-navi">
            <span>{{ $t("common.next") }}</span>
            <span v-html="ArrowRight"></span>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
  <div
    v-else
    class="total-user flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <MenuTab />
    <div class="header w-full flex-wrap flex justify-between items-end gap-[20px]">
      <div class="flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.total_users") }}
          </h2>
        </div>
        <div class="breadcrumb">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="box-search-user">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-full h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchKey"
            @keydown.enter="searchUserRenderDebounce(searchKey)"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            v-on:click="searchUserRenderDebounce(searchKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center gap-[10px] noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            <span v-if="!loadingSearch" class="whitespace-nowrap">{{
              $t("common.exploration")
            }}</span>
            <span v-if="loadingSearch">
              <a-spin :indicator="indicatorLoadingSearch" />
            </span>
          </button>
        </label>
      </div>
      <SelectUser :handleSelectStatusUserFilter="handleSelectStatusUserFilter" />
    </div>
    <div
      v-if="userRender && userRender.length > 0"
      class="w-full min-h-[70vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[36px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <UserItem v-for="user in userRender" :user="user" :key="user._id" />
      </div>
    </div>
    <div
      v-if="userRender && userRender.length <= 0 && loadingApi"
      class="w-full min-h-[75vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[24px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <a-spin :indicator="indicatorLoadingApi" />
      </div>
    </div>
    <div
      v-if="userRender && userRender.length <= 0 && !loadingApi"
      class="w-full min-h-[75vh] flex justify-center items-center"
    >
      <div
        class="flex flex-wrap justify-center items-center w-full gap-[24px] mt-[24px] mb-[40px]"
        :style="{ 'max-width: calc(100% - 48px)': true }"
      >
        <EmptyData />
      </div>
    </div>
    <div
      :class="{
        'nav-page w-full flex justify-center items-center w-1/1 h-[40px] ': true,
      }"
      v-if="usersLen && usersLen > 0"
    >
      <a-pagination
        v-model:current="pageCurrent"
        :total="usersLen"
        :pageSize="pageSize"
        :showSizeChanger="false"
        :responsive="true"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'" class="btn-navi">
            <span v-html="ArrowLeft"></span>
            <span>{{ $t("common.previous") }}</span>
          </a>
          <a v-else-if="type === 'next'" class="btn-navi">
            <span>{{ $t("common.next") }}</span>
            <span v-html="ArrowRight"></span>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { ArrowLeft, ArrowRight, SearchIcon } from "../../../assets/constant/svg";
import { containsNonWhitespaceCharacters, useDebounce } from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import EmptyData from "../../master/EmptyData.vue";
import UserItem from "./components/UserItem.vue";
import MenuTab from "./components/MenuTab.vue";
import SelectUser from "./components/SelectUser.vue";
const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const loadingApi = ref(false);
const loadingSearch = ref(false);
const indicatorLoadingApi = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const indicatorLoadingSearch = h(LoadingOutlined, {
  style: {
    fontSize: "20px",
    color: "white",
  },
  spin: true,
});
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/user-management/total-users",
    breadcrumbName: t("common.user_management"),
  },
  {
    path: "/user-management/total-users",
    breadcrumbName: t("common.total_users"),
  },
]);
const selectedStatusUserFilter = ref({
  id: 0,
  status: "all",
  name: t("common.all_accounts"),
});
const handleSelectStatusUserFilter = (item) => {
  router.replace({
    query: { ...route.query, status: item.status },
  });
  pageCurrent.value = 1;
  selectedStatusUserFilter.value = item;
};
const searchKey = ref("");
const pageCurrent = ref(1);
const pageSize = ref(15);
const usersLen = computed(() =>
  store.getters.allUserLen({
    filterStatusUser: selectedStatusUserFilter.value.status,
    search: searchKey.value,
  })
);
const users = computed(() =>
  store.getters.allUserListByPageGetters({
    page: pageCurrent.value,
    pageSize: pageSize.value,
    filterStatusUser: selectedStatusUserFilter.value.status,
    search: searchKey.value,
  })
);
const userRender = ref([]);
const searchUserRender = (searchKey) => {
  const searchToLowerCase = searchKey.toLowerCase();
  if (!searchKey || !containsNonWhitespaceCharacters(searchKey)) {
    userRender.value = users.value;
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      delete newQuery.search;
      router.replace({
        query: newQuery,
      });
    }, 200);
  } else {
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      router.replace({
        query: newQuery,
      });
    }, 200);
    userRender.value = users.value.filter(
      (user) =>
        user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())
    );
  }
  loadingSearch.value = false;
};
const searchUserRenderDebounce = useDebounce(searchUserRender, 1000);
watch([searchKey], () => {
  loadingSearch.value = true;
  pageCurrent.value = 1;
  searchUserRenderDebounce(searchKey.value);
});
watch([users], () => {
  searchUserRenderDebounce(searchKey.value);
});
watch(pageCurrent, () => {
  router.replace({
    query: { ...route.query, page: pageCurrent.value },
  });
  searchUserRender(searchKey.value);
});
watch(route.path, () => {
  handleGetQueryFromPath();
});
const handleGetQueryFromPath = () => {
  const search = route.query.search;
  const page = route.query.page;
  if (search && containsNonWhitespaceCharacters(search)) {
    searchKey.value = search;
  }
  if (page) {
    pageCurrent.value = parseInt(page);
  }
};
onMounted(() => {
  if (users.value.length <= 0) {
    loadingApi.value = true;
    store.dispatch("getAllUsers");
    setTimeout(() => {
      loadingApi.value = false;
    }, 1000);
  }
  handleGetQueryFromPath();
  searchUserRender(searchKey.value);
});
</script>
<style lang="scss">
@media only screen and (max-width: 1250px) {
  .box-select-status-user,
  .box-search-user {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
.ant-pagination-item {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  text-align: center !important;
  border: none !important;
  font-family: "NotoSansJP_300" !important;
  font-size: 14px !important;
  a {
    color: var(--color-text-gray-dark) !important;
  }
}

.ant-pagination-item-active {
  background-color: var(--primary-color) !important;
  a {
    color: var(--color-text-light) !important;
  }
}

.ant-pagination-next,
.ant-pagination-prev {
  a {
    font-size: 14px;
    font-family: "NotoSansJP_600" !important;
    color: var(--primary-color);
  }

  path {
    stroke: var(--primary-color);
  }
}

.ant-pagination-disabled {
  a {
    color: var(--color-text-gray) !important;
  }

  path {
    stroke: var(--color-text-gray);
  }
}

.btn-navi {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
</style>
