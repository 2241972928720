<template>
  <div
    v-if="!layoutMobile"
    class="account-permission flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.account_permission") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="rightside">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-[380px] h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchUserKey"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            @click="searchUserRender(searchUserKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            {{ $t("common.exploration") }}
          </button>
        </label>
      </div>
    </div>
    <div
      class="flex flex-col justify-between items-start w-full min-h-[65vh] mt-[24px] mb-[40px] rounded-[12px] border-solid border-[#D0D5DD] border-[1px] bg-white"
      :style="{ 'max-width: calc(100% - 48px)': true }"
    >
      <div
        class="header flex justify-between items-center w-full h-[68px] px-[24px] py-[12px] bg-[#475467] rounded-t-[12px]"
      >
        <p class="noto700 text-[20px] leading-[28px] text-white">
          {{ userRender.length ?? 0 }}
          {{ " " + $t("common.the_account_has_requested_permission") }}
        </p>
        <div class="cursor-pointer">
          <a-date-picker
            class="px-[14px] py-[12px]"
            :format="dateFormat"
            picker="date"
            :allowClear="false"
            :placeholder="''"
            v-model:value="dateSelected"
            :locale="locale"
          >
            <template #suffixIcon>
              <span v-html="DateIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <SelectShop :handleSelectShop="handleSelectShop" />
      </div>
      <div
        v-if="userRender && userRender.length > 0 && !loadingApi"
        class="bg-white flex flex-wrap justify-center items-center mx-auto w-full h-full overflow-y-auto rounded-[12px] scroll-bar px-[24px] py-[20px] gap-[24px]"
      >
        <UserItemChecked
          v-for="user in userRender"
          :key="user._id"
          :user="user"
          :selectAll="selectAll"
          :handlePushOrDeleteAllowArray="handlePushOrDeleteAllowArray"
        />
      </div>
      <div
        v-if="(!userRender || userRender.length <= 0) && !loadingApi"
        class="h-full bg-white flex flex-wrap justify-center items-center rounded-[12px] w-full min-h-[550px] max-h-[646px] overflow-y-auto scroll-bar scroll-bar-small px-[24px] py-[20px] gap-[24px]"
      >
        <EmptyData />
      </div>
      <div
        v-if="userRender && userRender.length <= 0 && loadingApi"
        class="h-full bg-white flex flex-wrap justify-center items-center rounded-[12px] w-full min-h-[550px] max-h-[646px] overflow-y-auto scroll-bar scroll-bar-small px-[24px] py-[20px] gap-[24px]"
      >
        <a-spin :indicator="indicatorLoadingApi" />
      </div>
      <div
        v-if="userRender && userRender.length > 0"
        class="footer w-full h-[82px] flex justify-between items-center px-[24px] border-solid border-[#D0D5DD] border-t-[1px]"
      >
        <button
          :disabled="!userRender || userRender.length <= 0"
          class="checked-select-all flex justify-start items-center gap-[8px]"
          v-on:click="handleChangeStatusSelectAll"
        >
          <span v-html="selectAll ? CheckedBlue : CheckedSquareGray"></span>
          <span class="noto500 text-[16px] leading-[24px] text-[#667085]"
            >{{ $t("common.select_all") }}
          </span>
        </button>
        <button
          v-on:click="handleAllowPermission"
          :disabled="processAllowPermission"
          class="flex justify-center items-center gap-[5px] w-[120px] h-[42px] rounded-[8px] bg-[#F38744] text-white noto500 text-[14px] leading-[18px]"
        >
          <span>{{ $t("common.permission") }}</span>
          <span class="flex justify-center items-center" v-if="processAllowPermission">
            <a-spin :indicator="indicator" />
          </span>
        </button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="account-permission flex flex-col justify-start items-start px-[24px] py-[21px] bg-[#f9fafb]"
  >
    <MenuTab />
    <div class="header w-full flex-wrap flex justify-between items-end gap-[40px]">
      <div class="leftside flex flex-col justify-end items-start gap-[15px]">
        <div class="title">
          <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
            {{ $t("common.account_permission") }}
          </h2>
        </div>
        <div class="search-box">
          <Breadcrumd :routes="routes" />
        </div>
      </div>
      <div class="rightside w-full">
        <label
          class="box-input flex justify-center items-center gap-[8px] w-full h-[44px] bg-white border-solid border-[#D0D5DD] border-[1px] rounded-[8px]"
        >
          <span class="w-[20px] ml-[14px]" v-html="SearchIcon"></span>
          <input
            v-model="searchUserKey"
            type="text"
            class="flex-1 h-full text-[16px] leading-[24px] outline-none placeholder:text-[16px]"
            :placeholder="$t('common.search_user')"
          />
          <button
            @click="searchUserRender(searchUserKey)"
            class="w-[60px] bg-[#F38744] h-[105%] translate-x-[2px] flex justify-center items-center noto400 text-[14px] leading-[22px] px-[12px] py-[6px] rounded-r-[8px] border-solid border-[1px] border-[#F38744] text-white"
            style="border-left: 1px solid #d0d5dd"
          >
            {{ $t("common.exploration") }}
          </button>
        </label>
      </div>
    </div>
    <div
      class="flex flex-col justify-between items-start w-full min-h-[65vh] mt-[24px] mb-[40px] rounded-[12px] border-solid border-[#D0D5DD] border-[1px] bg-white"
      :style="{ 'max-width: calc(100% - 48px)': true }"
    >
      <div
        class="header flex justify-between items-center w-full h-[68px] px-[24px] py-[12px] bg-[#475467] rounded-t-[12px]"
      >
        <p class="noto700 text-[20px] leading-[28px] text-white">
          {{ userRender.length ?? 0 }}
          {{ " " + $t("common.the_account_has_requested_permission") }}
        </p>
      </div>
      <div
        class="flex flex-col justify-center items-center gap-[12px] w-full pt-[20px] px-[20px]"
      >
        <div class="cursor-pointer w-full">
          <a-date-picker
            class="px-[14px] py-[12px] !w-full"
            :format="dateFormat"
            picker="date"
            :allowClear="false"
            :placeholder="''"
            v-model:value="dateSelected"
            :locale="locale"
          >
            <template #suffixIcon>
              <span v-html="DateIcon"></span>
            </template>
          </a-date-picker>
        </div>
        <SelectShop :handleSelectShop="handleSelectShop" />
      </div>
      <div
        v-if="userRender && userRender.length > 0 && !loadingApi"
        class="bg-white flex flex-1 flex-wrap justify-center items-center mx-auto w-full h-full min-h-[640px] overflow-y-auto rounded-[12px] scroll-bar px-[24px] py-[20px] gap-[24px]"
      >
        <UserItemChecked
          v-for="user in userRender"
          :key="user._id"
          :user="user"
          :selectAll="selectAll"
          :handlePushOrDeleteAllowArray="handlePushOrDeleteAllowArray"
        />
      </div>
      <div
        v-if="(!userRender || userRender.length <= 0) && !loadingApi"
        class="h-full bg-white flex flex-1 flex-wrap justify-center items-center rounded-[12px] w-full min-h-[640px] overflow-y-auto scroll-bar scroll-bar-small px-[24px] py-[20px] gap-[24px]"
      >
        <EmptyData />
      </div>
      <div
        v-if="userRender && userRender.length <= 0 && loadingApi"
        class="h-full bg-white flex flex-1 flex-wrap justify-center items-center rounded-[12px] w-full min-h-[640px] overflow-y-auto scroll-bar scroll-bar-small px-[24px] py-[20px] gap-[24px]"
      >
        <a-spin :indicator="indicatorLoadingApi" />
      </div>
      <div
        v-if="userRender && userRender.length > 0"
        class="footer w-full h-[82px] flex justify-between items-center px-[24px] border-solid border-[#D0D5DD] border-t-[1px]"
      >
        <button
          :disabled="!userRender || userRender.length <= 0"
          class="checked-select-all flex justify-start items-center gap-[8px]"
          v-on:click="handleChangeStatusSelectAll"
        >
          <span v-html="selectAll ? CheckedBlue : CheckedSquareGray"></span>
          <span class="noto500 text-[16px] leading-[24px] text-[#667085]"
            >{{ $t("common.select_all") }}
          </span>
        </button>
        <button
          v-on:click="handleAllowPermission"
          :disabled="processAllowPermission"
          class="flex justify-center items-center gap-[5px] w-[120px] h-[42px] rounded-[8px] bg-[#F38744] text-white noto500 text-[14px] leading-[18px]"
        >
          <span>{{ $t("common.permission") }}</span>
          <span class="flex justify-center items-center" v-if="processAllowPermission">
            <a-spin :indicator="indicator" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from "@ant-design/icons-vue";
import locale from "ant-design-vue/es/date-picker/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import moment from "moment";
import { computed, h, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  CheckedBlue,
  CheckedSquareGray,
  DateIcon,
  SearchIcon,
} from "../../../assets/constant/svg";
import {
  addModal,
  containsNonWhitespaceCharacters,
  getCurrentDate,
  useDebounce,
} from "../../../helpers";
import Breadcrumd from "../../master/Breadcrumd.vue";
import EmptyData from "../../master/EmptyData.vue";
import ConfirmModal from "./components/ConfirmModal.vue";
import UserItemChecked from "./components/UserItemChecked.vue";
import MenuTab from "./components/MenuTab.vue";
import SelectShop from "./components/SelectShop.vue";
const { t } = useI18n();
const dateFormat = "YYYY年M月D日";
dayjs.locale("ja");
const dateSelected = ref(dayjs(getCurrentDate(), "YYYY-MM-DD"));
const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const routes = ref([
  {
    path: "/dashboard",
    breadcrumbName: t("common.home"),
  },
  {
    path: "/user-management/total-users",
    breadcrumbName: t("common.user_management"),
  },
  {
    path: "/user-management/account-permission",
    breadcrumbName: t("common.account_permission"),
  },
]);

const processAllowPermission = ref(false);
const loadingApi = ref(false);
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: "18px",
    color: "white",
  },
  spin: true,
});
const indicatorLoadingApi = h(LoadingOutlined, {
  style: {
    fontSize: "40px",
    color: "rgba(239, 104, 32, 1)",
  },
  spin: true,
});
const shopSelected = ref(null);
const handleSelectShop = (shop) => {
  router.replace({
    query: { ...route.query, shop: shop._id },
  });
  shopSelected.value = shop;
};

// user
const loadingSearchUser = ref(false);
const arrUserAllow = ref([]);
const users = computed(() =>
  store.getters.userInShopListRequestApproval({
    dateFilterHook: moment(dateSelected.value.$d).format("YYYY-MM-DD"),
  })
);
const userRender = ref([]);
const searchUserKey = ref("");
const searchUserRender = (searchKey) => {
  const searchToLowerCase = searchKey.toLowerCase();
  if (!searchKey || !containsNonWhitespaceCharacters(searchKey)) {
    userRender.value = users.value;
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      delete newQuery.search;
      router.push({
        query: newQuery,
      });
    }, 200);
  } else {
    setTimeout(() => {
      const newQuery = { ...route.query, search: searchKey };
      router.push({
        query: newQuery,
      });
    }, 200);
    userRender.value = users.value.filter((user) => {
      return (
        user.nickName.toLowerCase().includes(searchToLowerCase) ||
        user.userName.toLowerCase().includes(searchToLowerCase) ||
        searchToLowerCase.includes(user.nickName.toLowerCase()) ||
        searchToLowerCase.includes(user.userName.toLowerCase())
      );
    });
  }
  loadingSearchUser.value = false;
};
const searchUserRenderDebounce = useDebounce(searchUserRender, 1000);
watch(searchUserKey, () => {
  loadingSearchUser.value = true;
  searchUserRenderDebounce(searchUserKey.value);
});
watch(users, () => {
  searchUserRender(searchUserKey.value);
});
watch(dateSelected, () => {
  router.replace({
    query: {
      ...route.query,
      date: dayjs(dateSelected.value.$d).format("YYYY-MM-DD"),
    },
  });
});

// user checked and call api put
const selectAll = ref(false);
const handleChangeStatusSelectAll = () => {
  selectAll.value = !selectAll.value;
};
watch(selectAll, () => {
  if (selectAll) {
    arrUserAllow.value = users.value.map((user) => user._id);
  }
});
const handlePushOrDeleteAllowArray = (userId, status) => {
  if (status) {
    arrUserAllow.value.push(userId);
  } else {
    arrUserAllow.value = arrUserAllow.value.filter((id) => id !== userId);
  }
};
const handleAllowPermission = () => {
  if (arrUserAllow.value.length > 0) {
    addModal(ConfirmModal, {
      onConfirm: () => {
        store.dispatch("allowPermission", {
          data: { userIds: arrUserAllow.value },
          onSuccess: () => {
            store.dispatch("getAllUsers");
            store.dispatch("getUserInShop", shopSelected.value._id);
            processAllowPermission.value = false;
          },
        });
      },
      title: t("common.do_you_want_allow_permission"),
      textBtnClose: t("common.cancel"),
      textBtnConfirm: t("common.confirm"),
    });
  }
};

// mounted
const handleGetQueryFromPath = () => {
  const date = route.query.date;
  const search = route.query.search;
  if (date) {
    dateSelected.value = dayjs(date, "YYYY-MM-DD");
  }
  if (search) {
    searchUserKey.value = search;
  }
};
watch(shopSelected, () => {
  if (shopSelected.value._id) {
    store.dispatch("getUserInShop", shopSelected.value._id);
  }
});
onMounted(() => {
  loadingApi.value = true;
  handleGetQueryFromPath();
  setTimeout(() => {
    loadingApi.value = false;
  }, 1000);
});
</script>
<style lang="scss">
@import "../../../assets/css/views/_ant_custom";
.account-permission {
  .ant-picker {
    width: 160px;
    height: 44px;
    padding: 12px 14px;
    border: 1px solid #d0d5dd;
  }
}
</style>
